import React, { memo, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { constants } from '../app.constants';
import { PublicSubscriptionCard } from '../components';
import { fetchPublicPlans } from '../services/plans.service';

const PublicPlansSection = () => {
  const navigate = useNavigate();
  const [packages, setPackages] = useState<any>([]);

  const getPackages = useCallback(async () => {
    try {
      const response = await fetchPublicPlans();
      if (response) {
        setPackages(response);
      }
    } catch (error) {
      toast.error('Failed to load plans.');
    }
  }, []);

  useEffect(() => {
    getPackages();
  }, []);

  return (
    <div className='py-12 border-b border-gray-300' id='packages'>
      <div className='flex flex-col gap-12 items-center max-w-7xl mx-auto px-5'>
        <div className='flex flex-col justify-center items-center text-center gap-4'>
          <p className='text-2xl lg:text-4xl'>Ready to simplify your legal journey?</p>
          <p className='text-lg lg:text-2xl'>Choose your Plan today and unlock the clarity and support you deserve.</p>
          <p>By subscribing to a EastLaw package, you agree to <a className='font-bold text-primary cursor-pointer hover:underline' onClick={() => { window.open('/terms-of-use', '_blank', 'noopener,noreferrer') }}>our terms</a> and <a className='font-bold text-primary cursor-pointer hover:underline' onClick={() => { window.open('/privacy-policy', '_blank', 'noopener,noreferrer') }}>privacy policy</a>.</p>
        </div>
        <div className='flex flex-wrap gap-12 justify-center'>
          {packages && packages.map((plan: any) => (
            <PublicSubscriptionCard key={plan.name} plan={{
              id: plan.id,
              name: plan.name,
              price: plan.price,
              activationPeriod: plan.activationPeriod,
              type: 'Free Users' === plan.name ? 'free' : 'other',
              features: plan.features,
            }}
              action={() => navigate('/auth?current=signup')}
              actionLabel={'Get Started'}
              isActionDisabled={false} />
          ))}
          <PublicSubscriptionCard key={'999'} plan={{
            id: '999',
            name: 'Corporate Plan',
            type: 'corporate',
            features: constants.eastlawCorporateFeatures,
          }}
            action={() => navigate('/corporate-pricing-policy')}
            actionLabel={'Click here for Details'}
            isActionDisabled={false} />
        </div>
      </div>
    </div >
  );
};

export default memo(PublicPlansSection);
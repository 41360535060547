import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material';
import Login from './login';
import SignUp from './signup';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { encryptString } from '../../app.utils';
import { useLoader } from '../../providers';
import { useScrollToTop } from '../../app.hooks';
import axios from 'axios';
import { constants } from '../../app.constants';

export const Auth = () => {
  useScrollToTop();

  const { showLoader, hideLoader } = useLoader();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [selectedOption, setSelectedOption] = useState(searchParams.get('current') || 'signin');
  const orgId = searchParams.get('orgId') || '';

  const signinOrg = async () => {
    showLoader();
    try {
      let response = await axios.post(constants.apiUrl + '/auth/org', { orgId });
      if (response.data.user && response.data.token) {
        localStorage.setItem('isAuthenticated', encryptString('true'));
        localStorage.setItem('username', encryptString(response.data.user.name));
        localStorage.setItem('userId', encryptString(response.data.user.id));
        localStorage.setItem('role', encryptString(response.data.user.role));
        localStorage.setItem('token', encryptString(response.data.token));
        localStorage.setItem('isVerified', encryptString((response.data.user.emailVerified).toString()));
        if (response.data.user.emailVerified === true) {
          toast.success('Logged in successfully!');
          window.location.href = '/home';
        }
      }
    } catch {
      toast.error("Failed to login. Please make sure you access EastLaw within your organization's network.");
    } finally {
      hideLoader();
    }
  }

  useEffect(() => {
    if (orgId !== '') {
      signinOrg();
    }
  }, []);

  return (
    <>
      <div className="hidden lg:flex min-h-[calc(100vh-5rem)]">
        <div className="flex flex-row w-full">
          {/* Left Section */}
          <div className="w-1/2 h-full bg-center bg-cover bg-gavel">
            <div className='w-full h-full backdrop-blur-sm bg-black/70'>
              <div className="flex flex-col items-center justify-center w-full h-full gap-6">
                {selectedOption === 'signin' ? (
                  <div className="flex flex-col items-center justify-center gap-6">
                    <p className="text-4xl font-normal text-center text-white">
                      Don't have an account?<br />Register your account to get started.
                    </p>
                    <div onClick={() => setSelectedOption('signup')} className='flex flex-row items-center justify-center gap-4 py-2 text-black transition-transform transform bg-white border border-gray-300 rounded-full cursor-pointer w-28 hover:scale-105'>
                      <span className='font-semibold'>Register</span>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center gap-6">
                    <p className="text-4xl text-center text-white">
                      Already have an account?<br />Login to continue.
                    </p>

                    <div onClick={() => setSelectedOption('signin')} className='flex flex-row items-center justify-center gap-4 py-2 text-black transition-transform transform bg-white border border-gray-300 rounded-full cursor-pointer w-28 hover:scale-105'>
                      <span className='font-semibold'>Login</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Right Section */}
          <div className="flex flex-col items-center justify-center w-1/2 bg-white">
            <div className="w-4/5 mx-auto">
              <div className="w-full ">
                {selectedOption === 'signup' ? <SignUp /> : <Login />}
              </div>
            </div>
          </div>

        </div>
      </div>

      {/* Mobile View */}
      <div className="flex flex-col items-center min-h-screen gap-16 py-16 bg-black lg:hidden">
        {selectedOption === 'signin' ? (
          <div className="flex flex-col items-center justify-center gap-4 px-4">
            <p className="text-3xl font-semibold text-center text-white">
              Don't have an account?<br />Register your account to get started.
            </p>
            <Button
              variant="contained"
              color="primary"
              className="px-8 py-3 mt-4 rounded-full"
              onClick={() => setSelectedOption('signup')}
            >
              Register
            </Button>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center gap-4 px-4">
            <p className="text-3xl font-semibold text-center text-white">
              Already have an account?<br />Login to continue.
            </p>
            <Button
              variant="contained"
              color="primary"
              className="px-8 py-3 mt-4 rounded-full"
              onClick={() => setSelectedOption('signin')}
            >
              Login
            </Button>
          </div>
        )}
        <div className="px-4 pt-5 pb-4 bg-white rounded-lg shadow-xl w-[90%]">
          {selectedOption === 'signup' ? <SignUp /> : <Login />}
        </div>
      </div>

    </>
  );
};

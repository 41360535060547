import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { constants } from '../app.constants';
import { Banner } from '../components';

gsap.registerPlugin(ScrollTrigger);

export const Header: React.FC = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setIsOpen(open);
  };

  const scrollUp = (id: string, e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const element = document.getElementById(id);
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  const menuItems = [
    { text: 'Home', id: 'home', path: '/' },
    { text: 'Packages', id: 'packages', path: '/' },
    { text: 'About Us', id: 'about', path: '/' },
    { text: 'Contact Us', id: 'contact', path: '/' },
    { text: 'Legal Awareness', id: 'legal-news/list', path: '/legal-news/list' },
  ];

  return (
    <div className="fixed top-0 z-50 flex flex-col w-full bg-white shadow-md">
      <div className="flex flex-row items-center justify-between px-4 py-4 lg:px-12 xl:px-24">
        <div className='flex flex-row items-center gap-2'>
          <div className="lg:hidden">
            <IconButton
              size='large'
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <img
            onClick={() => navigate('/')}
            className="w-48 h-auto cursor-pointer xl:w-56"
            src={constants.svgs.eastLawLogo}
            alt="EastLaw"
          />
        </div>
        <Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)}>
          <div className="flex flex-col w-64 gap-4 px-4 pt-4" role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <img
              onClick={() => navigate('/')}
              className="h-auto mb-3 cursor-pointer"
              src={constants.svgs.eastLawLogo}
              alt="EastLaw"
            />
            {menuItems.map((item, index) => (
              <a
                key={index}
                className="cursor-pointer nav-item max-w-max"
                onClick={(e) => {
                  navigate(item.path);
                  scrollUp(item.id, e);
                }}
              >
                {item.text}
              </a>
            ))}
            <a className="cursor-pointer nav-item max-w-max" onClick={() => navigate('/auth')}>
              Login | Register
            </a>
          </div>
        </Drawer>
        <div className="hidden lg:flex lg:w-auto lg:items-center lg:gap-4 xl:gap-6">
          {menuItems.map((item, index) => (
            <a
              key={index}
              className="cursor-pointer nav-item"
              onClick={(e) => {
                navigate(item.path);
                scrollUp(item.id, e);
              }}
            >
              {item.text}
            </a>
          ))}
          <a className="cursor-pointer" onClick={() => navigate('/auth')}>
            <button className="px-4 py-2 font-semibold text-white transition-transform duration-200 rounded-full bg-primary hover:scale-110 focus:scale-90">
              Login | Register
            </button>
          </a>
        </div>
      </div>
      <Banner />
    </div>
  );
};
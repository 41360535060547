import React, { useState } from "react";
import { TextField, Button, Dialog, DialogContent, IconButton } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import axios from "axios";
import { toast } from "react-toastify";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { decryptString } from "../app.utils";
import { sendCorporatePlanRequestEmail } from "../services/user.service";

export type CorporatePlanRequestDialogProps = {
  open: boolean;
  onClose: () => void;
  selectedPlan: string;
};

export const CorporatePlanRequest = ({ open, onClose, selectedPlan }: CorporatePlanRequestDialogProps) => {
  const [emailMessage, setEmailMessage] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [contactNo, setContactNo] = useState<any>("");
  const [formErrors, setFormErrors] = useState<any>({});
  const [touchedFields, setTouchedFields] = useState<any>({});

  const handleInputChange = (event: any) => {
    let errors: any = {};
    const { name, value } = event.target;
    switch (name) {
      case 'email': {
        setEmail(value);
        if (!value) {
          errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          errors.email = 'Email is invalid';
        } else {
          delete formErrors.email;
        }
        break;
      }
      case 'name': {
        setName(value);
        if (!value) {
          errors.name = 'Name is required';
        } else {
          delete formErrors.name;
        }
        break;
      }
      case "emailMessage": {
        if (value.length <= 500) {
          setEmailMessage(value);
        }
        if (!value) {
          errors.emailMessage = "Email Message is required";
        } else {
          delete formErrors.emailMessage;
        }
        break;
      }
      default:
        break;
    }
    setFormErrors((prevErrors: any) => {
      return { ...prevErrors, ...errors };
    });
  };

  const handlePhoneInputChange = (value: string, name: string) => {
    let newErrors: any = {};
    if (!value) {
      newErrors.contactNo = 'Phone No. is required';
    } else {
      delete formErrors.contactNo;
    }
    setContactNo(value);
    setFormErrors((prevErrors: any) => {
      return { ...prevErrors, ...newErrors };
    });
  };

  const handleFieldBlur = (event: any) => {
    const { name } = event.target;
    setTouchedFields((prevTouched: any) => {
      return { ...prevTouched, [name]: true };
    });
  };

  const sendEmail = async () => {
    if (isFormValid()) {
      try {
        await sendCorporatePlanRequestEmail({ name, email, contactNo, emailMessage, selectedPlan });
        toast.success("Email sent successfully.");
        setEmailMessage('');
        onClose();
      } catch {
        toast.error("Failed to send email.");
      }
    }
  };

  const isFormValid = () => {
    return name.length > 0 && email.length > 0 && contactNo && contactNo.length > 0 && emailMessage.length > 0 && Object.keys(formErrors).length === 0;
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <div className="flex justify-end items-center bg-white border-b border-gray-200 py-2 px-4">
        <IconButton
          onClick={onClose}
          size="small"
          className='action'
          sx={{ color: '#AD181D' }}>
          <HighlightOffIcon fontSize='small' />
        </IconButton>
      </div>
      <DialogContent>
        <div className="flex flex-col">
          <form className="flex flex-col gap-5 w-full h-full">
            <p className="text-4xl font-bold mt-1 gradient-text-red">Corporate Plan Request</p>

            <div className="flex flex-col gap-1 break-words bg-yellow-100 border border-yellow-400 rounded py-1 px-2">
              <p className="font-bold">Inquring About:</p>
              <p>{selectedPlan}</p>
            </div>
            <div className="flex flex-col gap-2">
              <label className='text-gray-500'>Name<b className="text-primary ml-1">*</b></label>
              <TextField
                size="small"
                required
                name="name"
                placeholder="Please enter your name"
                value={name}
                onChange={handleInputChange}
                onBlur={handleFieldBlur}
                error={!!(formErrors.name && touchedFields.name)}
                helperText={
                  touchedFields.name ? formErrors.name : ""
                }
              />
            </div>
            <div className="flex flex-col gap-2">
              <label className='text-gray-500'>Email<b className="text-primary ml-1">*</b></label>
              <TextField
                size="small"
                required
                name="email"
                placeholder="Please enter your email"
                value={email}
                onChange={handleInputChange}
                onBlur={handleFieldBlur}
                error={!!(formErrors.email && touchedFields.email)}
                helperText={
                  touchedFields.email ? formErrors.email : ""
                }
              />
            </div>
            <div className='flex flex-col w-full'>
              <label className='text-gray-500 mb-2'>Phone Number<b className="text-primary ml-1">*</b></label>
              <PhoneInputWithCountrySelect
                className={`w-full px-4 py-2 text-gray-500 bg-white border rounded ${touchedFields.contactNo && formErrors.contactNo ? 'border-[#d32f2f]' : 'border-gray-300'}`}
                defaultCountry='PK'
                name='contactNo'
                placeholder="Enter your phone no."
                onBlur={handleFieldBlur}
                error={!!(formErrors.contactNo && touchedFields.contactNo)}
                value={contactNo}
                onChange={(value: any) => handlePhoneInputChange(value, 'contactNo')} />
              <span className="text-[0.75rem] text-[#d32f2f] mt-[4px] ml-[14px]">{touchedFields.contactNo ? formErrors.contactNo : ""}</span>
            </div>
            <div className="flex flex-col gap-2">
              <label className='text-gray-500'>Email Message<b className="text-primary ml-1">*</b></label>
              <TextField
                rows={6}
                multiline={true}
                required
                size="small"
                id="emailMessage"
                name="emailMessage"
                placeholder="Please describe your query here."
                value={emailMessage}
                onChange={handleInputChange}
                onBlur={handleFieldBlur}
                error={!!(formErrors.emailMessage && touchedFields.emailMessage)}
                helperText={
                  touchedFields.emailMessage ? formErrors.emailMessage : ""
                }
              />
            </div>

          </form>
          <div className="mt-5">
            <Button
              className="w-full mt-4"
              disabled={!isFormValid()}
              variant="contained"
              color='primary'
              onClick={() => sendEmail()}
            >
              Send Email
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
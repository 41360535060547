import { Dialog } from "@mui/material";
import React, { createContext, useContext, useState, ReactNode } from "react";

interface DialogProps {
  type: React.ComponentType<any>;
  props: any;
}

interface DialogContextType {
  openDialog: (dialogType: React.ComponentType<any>, dialogProps: any) => void;
  closeDialog: () => void;
}

const DialogContext = createContext<DialogContextType | undefined>(undefined);

interface DialogProviderProps {
  children: ReactNode;
}

const DialogProvider: React.FC<DialogProviderProps> = ({ children }) => {
  const [dialogState, setDialogState] = useState<{ open: boolean, dialogs: DialogProps[] }>({ open: false, dialogs: [] });

  const openDialog = (dialogType: React.ComponentType<any>, dialogProps: any) => {
    setDialogState((prevState) => ({ open: true, dialogs: [...prevState.dialogs, { type: dialogType, props: dialogProps }] }));
  };

  const closeDialog = () => {
    setDialogState((prevState) => ({ open: false, dialogs: prevState.dialogs.slice(0, -1) }));
  };

  const currentDialog = dialogState.dialogs.length > 0 ? dialogState.dialogs[dialogState.dialogs.length - 1] : null;

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog }}>
      {children}
      {currentDialog && (
        <Dialog open={dialogState.open} onClose={closeDialog}>
          <currentDialog.type
            open={dialogState.open} // Pass the open prop here
            {...currentDialog.props}
          />
        </Dialog>
      )}
    </DialogContext.Provider>
  );
};

const useDialog = () => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error("useDialog must be used within a DialogProvider provider");
  }
  return context;
};

export { DialogProvider, useDialog };

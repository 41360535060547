// Material imports
import { createTheme } from "@mui/material/styles";

// Customising mui theme
const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          userSelect: 'none',
          backgroundColor: '#ffffff', // Light background
          color: '#000000', // Black text
          border: '1px solid rgba(0, 0, 0, 0.2)', // Light border
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // Shadow effect
        },
        arrow: {
          color: '#ffffff', // Match arrow color with tooltip background
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '0.45rem',
        }
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: "#f3f5f7",
          position: "sticky",
          top: 0,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          left: '8rem', // shift dialog to 8rem (side nav width) left
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '0.45rem',
        },
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#AD181D',
            backgroundColor: 'white',
          },
        },
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          '&.Mui-focused': {
            color: '#AD181D',
            backgroundColor: 'white',
          }
        },
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'gray',
              borderWidth: '1.5px',
            }
          }
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#AD181D',
    },
    success: {
      main: '#50C878',
      contrastText: 'white',
    },
  },
});

export default theme;
import React, { useEffect, useState } from "react";
import { TextField, Button, FormControl, InputLabel, MenuItem, Select, Dialog, DialogContent, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import { HighlightOff } from "@mui/icons-material";
import { getAllPlans, upgradeUserPlan } from "../services/user.service";

type UpdateUserPlanDialogProps = {
  open: boolean;
  onClose: () => void;
  data: {
    planId: string,
    email: string,
  }
};

export const UpdateUserPlan = ({ open, onClose, data }: UpdateUserPlanDialogProps) => {

  const [selectedPlanId, setUserPlanId] = useState<any>(data.planId);
  const [selectedPlan, setUserPlan] = useState<any>(null);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    fetchAllPlans();
  }, []);

  const fetchAllPlans = async () => {
    try {
      const res: any = await getAllPlans();
      setPlans(res);
      setUserPlan(findPlanById(selectedPlanId, res));
    } catch (err) {
      toast.error('Something went wrong.');
    }
  };

  const handleInputChange = (event: any) => {
    setUserPlanId(event.target.value);
    setUserPlan(findPlanById(event.target.value, plans));
  };

  const submitUpgradePlanForm = async () => {
    try {
      await upgradeUserPlan({
        userEmail: data.email,
        newPlanId: selectedPlanId
      });

      toast.success('Plan Updated successfully.');
      resetForm();
      onClose();
    } catch (err) {
      toast.error('Something went wrong.');
    }
  };

  const resetForm = () => {
    setUserPlan(null);
  }

  const covertToLineByLine = (text: any) => {
    return (
      <ul className="mx-5 list-disc"
        dangerouslySetInnerHTML={{ __html: `<li>${text.replace(/\.\s(?!\s*$)/g, '.</li>\n<li>')}</li>` }}
      ></ul>
    )
  }

  const findPlanById = (id: string, tmpPlans: any) => {
    let filteredPlans = tmpPlans.filter((plan: any) => plan.id === id);
    if (filteredPlans.length > 0) {
      return filteredPlans[0];
    }
    return [];
  }
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <div className="flex justify-end items-center bg-white border-b border-gray-200 py-2 px-4">
        <IconButton
          onClick={() => { resetForm(); onClose(); }}
          size="small"
          className="action"
          sx={{ color: "#AD181D" }}
        >
          <HighlightOff fontSize="small" />
        </IconButton>
      </div>
      <DialogContent>
        <div className="flex flex-col">
          <form className="flex flex-col gap-4 w-full h-full">
            <p className="text-3xl font-bold my-2 gradient-text-red">Change User Plan</p>
            <div className="flex flex-col gap-2">
              <label className='font-bold'>Email</label>
              <TextField
                size="small"
                required
                name="email"
                placeholder="Enter your email"
                value={data.email}
                disabled
              />
            </div>
            {(plans && plans.length > 0) && (
              <>
                <div className="flex flex-col gap-2">
                  <label className='font-bold'>Change Plan</label>
                  <FormControl fullWidth size='small'>
                    <Select
                      variant='outlined'
                      name='selectedPlan'
                      value={selectedPlanId}
                      placeholder='Select Plan'
                      onChange={handleInputChange}
                    >
                      {plans.map((plan: any) => (
                        <MenuItem value={plan.id} key={plan.id}>
                          {plan.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {
                  selectedPlan && (
                    <div className="text-sm break-words bg-yellow-100 border border-yellow-400 rounded py-1 px-2 mt-2">
                      <p><b>Package Name: </b> {selectedPlan?.name}</p>
                      <p><b>Package Detail:</b></p>
                      <div>
                        {selectedPlan?.details ? (covertToLineByLine(selectedPlan?.details)) : 'N/A'}
                      </div>
                    </div>
                  )
                }
              </>
            )}
          </form>
          <div className="mt-6">
            <Button
              className="w-full mt-4"
              disabled={(!selectedPlan)}
              variant="contained"
              color='primary'
              onClick={submitUpgradePlanForm}
            >
              Save
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
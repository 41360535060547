// React import
import React, { useEffect } from 'react';

// Material import
import Box from '@mui/material/Box';
import ProtectedRoutes from '../routes/ProtectedRoutes';
import { Info } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Navigation } from '../components';
import { useForbiddenPrompt } from '../app.hooks';
import { getUserId, isAuthorised, isOutOfOrganization } from '../services/auth.service';
import { fetchBookmarks } from '../services/bookmarks.service';
import { constants } from '../app.constants';

// Main Container
export const SecureLayout = () => {
  const navigate = useNavigate();
  const openForbiddenPrompt = useForbiddenPrompt();

  useEffect(() => {
    const requestLink = localStorage.getItem('requestLink');
    if (requestLink) {
      navigate(requestLink);
      setTimeout(() => localStorage.removeItem('requestLink'), 500);
    }

    loadBookmarks();
  }, []);

  const loadBookmarks = async () => {
    try {
      await fetchBookmarks({
        pageNo: 1,
        pageSize: constants.pageSize,
        userId: getUserId(),
      });
    } catch (error: any) {
      if (error.response?.status === 403) {
        openForbiddenPrompt();
      }
    }
  };

  const upgradeRequestSent = localStorage.getItem('upgradeRequestSent') === 'true';

  return (
    <div className='flex flex-col w-full min-h-screen bg-[#fdfdfd]'>
      {!isAuthorised() && (
        <div className='bg-yellow-200 gap-2 text-black flex flex-row px-3 py-2 text-sm items-center z-[100] shadow-md w-full'>
          <Info color='primary' fontSize='small' />
          {isOutOfOrganization() ? (
            <span className='mx-1'>
              You do not have permission to access this resource. You might be accessing outside of your organization’s network.
            </span>
          ) : (
            upgradeRequestSent ? (
              <span className='mx-1'>
                You have requested an upgrade to Premium. Please follow instructions in the email received.
              </span>
            ) : (
              <span className='mx-1'>
                Upgrade to our premium plan for full access to all features. <a className='underline cursor-pointer text-primary' onClick={() => { navigate('/plans') }}>Click here</a> to upgrade to premium.
              </span>
            )
          )}
        </div>
      )
      }
      <div className={`flex flex-row ${!isAuthorised() ? 'h-[calc(100vh-2.25rem)]' : 'h-screen'} w-full`}>
        <div className='flex flex-row w-full'>
          <div className='z-50 flex-1'>
            <Navigation />
          </div>
          <div className='relative flex flex-col w-full overflow-x-auto'>
            <div className='w-full h-screen overflow-auto'>
              <Box component='main' className='z-10 flex flex-col flex-1 w-full h-full p-4'>
                <ProtectedRoutes />
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
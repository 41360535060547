import React from "react";
import { Loading02Icon } from "hugeicons-react";

export const ContainerLoader = ({ text = 'Loading...', isLoading = false }) => {

  return (
    <>
      {isLoading && (
        <div className='absolute inset-0 bg-[#e7e9ebde] z-40'>
          <div className="flex flex-col items-center justify-center h-full gap-2">
            <Loading02Icon className="w-16 h-16 text-gray-700 animate-spin" />
          </div>
        </div>
      )}
    </>
  );
};
import React, { } from 'react';
import { decryptString, formatDate } from '../app.utils';

type JudgemtPrintProps = {
  judgementData: any;
};

export const JudgmentPrint: React.FC<JudgemtPrintProps> = ({ judgementData }) => {

  function addCommasToStrings(strings: any) {
    if (!Array.isArray(strings) || strings.length === 0) {
      return 'N/A';
    }
    return strings.slice(0, -1).join(', ') + (strings.length > 1 ? ', ' : '') + strings.slice(-1);
  }

  const userName = decryptString(localStorage.getItem('username'));
  const currentDateTime = new Date().toISOString();

  return (
    <>
      <div className='watermark'>
        www.eastlaw.pk
      </div>
      <div className='flex flex-col gap-8 py-4'>
        <div className='flex flex-col gap-2 border border-black p-2 text-[0.6rem]'>
          <p>
            <b className='text-red-600'>Disclaimer:</b> While every effort has been made to ensure the accuracy and currency, we do not guarantee completeness, correctness, or applicability to specific
            cases of court judgments available on this website. The court judgment may be subject to updates, modifications, or revisions by the competent authorities.
            Therefore, it is advisable to consult the official sources or legal professionals for the most up-to-date and accurate information.</p>
          <p>
            The court judgments available on this website are not official publications of the Government of Pakistan. It may not reflect the most recent or authoritative
            version of the judgment. For official and legally binding versions, please refer to the official sources/authorized publications/certified copies of court orders.
          </p>
          <p>
            The use of the documents available on this website is at your own risk. We shall not be held liable for any loss, damage, or inconvenience arising from the use or
            reliance on the information contained in the data. We disclaim any responsibility for errors, omissions, or inaccuracies in the court judgment or any consequences
            resulting from its use.
          </p>
        </div>
        <table className='text-[0.9rem] flex flex-col'>
          <tr>
            <td className='p-2 min-w-[10rem]'>
              <p className='font-bold'>Court Name:</p>
            </td>
            <td className='p-2'>
              <p>{judgementData.court || 'N/A'}</p>
            </td>
          </tr>
          <tr>
            <td className='p-2 min-w-[10rem]'>
              <p className='font-bold'>Judge(s):</p>
            </td>
            <td className='p-2'>
              <p>{judgementData.judgesId && addCommasToStrings(judgementData.judgesId.map((obj: any) => obj.name))}</p>
            </td>
          </tr>
          <tr>
            <td className='p-2 min-w-[10rem]'>
              <p className='font-bold'>Title:</p>
            </td>
            <td className='p-2'>
              <p>{(judgementData.appeallant || 'N/A') + ' vs ' + (judgementData.respondant || 'N/A')}</p>
            </td>
          </tr>
          <tr>
            <td className='p-2 min-w-[10rem]'>
              <p className='font-bold'>Case No.:</p>
            </td>
            <td className='p-2'>
              <p>{judgementData.appeal}</p>
            </td>
          </tr>
          <tr>
            <td className='p-2 min-w-[10rem]'>
              <p className='font-bold'>Date of Judgment:</p>
            </td>
            <td className='p-2'>
              <p>{formatDate(judgementData.judgment_date) || 'N/A'}</p>
            </td>
          </tr>
          <tr>
            <td className='p-2 min-w-[10rem]'>
              <p className='font-bold'>Reported As:</p>
            </td>
            <td className='p-2'>
              <p>{judgementData.citationsId && addCommasToStrings(judgementData.citationsId.map((obj: any) => obj.citation))}</p>
            </td>
          </tr>
          <tr>
            <td className='p-2 min-w-[10rem]'>
              <p className='font-bold'>Result:</p>
            </td>
            <td className='p-2'>
              <p>{judgementData.result || 'N/A'}</p>
            </td>
          </tr>
        </table>
        <div className='flex flex-col gap-3'>
          <p className='text-xl font-bold'>Judgment</p>
          <div className='judgment-text text-[0.9rem] text-justify'
            dangerouslySetInnerHTML={{ __html: judgementData.judgment }}
          ></div>
        </div>
        <hr />
        <div className='flex flex-col gap-4 py-2'>
          <div className='flex flex-col gap-2 border border-black p-2 text-[0.6rem]'>
            <p>
              <b className='text-red-600'>Disclaimer:</b> While every effort has been made to ensure the accuracy and currency, we do not guarantee completeness, correctness, or applicability to specific
              cases of court judgments available on this website. The court judgment may be subject to updates, modifications, or revisions by the competent authorities.
              Therefore, it is advisable to consult the official sources or legal professionals for the most up-to-date and accurate information.</p>
            <p>
              The court judgments available on this website are not official publications of the Government of Pakistan. It may not reflect the most recent or authoritative
              version of the judgment. For official and legally binding versions, please refer to the official sources/authorized publications/certified copies of court orders.
            </p>
            <p>
              The use of the documents available on this website is at your own risk. We shall not be held liable for any loss, damage, or inconvenience arising from the use or
              reliance on the information contained in the data. We disclaim any responsibility for errors, omissions, or inaccuracies in the court judgment or any consequences
              resulting from its use.
            </p>
          </div>

          <div className='flex flex-row justify-between'>
            <p><b>Printed On: </b> {currentDateTime.split('T')[0]}</p>
            <p><b>Printed By: </b> {userName}</p>
          </div>

        </div>
      </div>
    </>
  );
};
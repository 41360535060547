import { Publish, RemoveCircle, UploadFile } from '@mui/icons-material';
import { Chip, Divider, IconButton } from '@mui/material';
import React, { useState, ChangeEvent } from 'react';

interface FileUploaderProps {
  onFilesUploaded: (files: File[]) => void;
  fileType?: string,
  containerLabel?: string,
  multiple?: boolean,
}

export const FileUploader: React.FC<FileUploaderProps> = ({ fileType = 'pdf', containerLabel = 'Upload PDF(s)', onFilesUploaded, multiple = true}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const files = e.target.files;

    if (!files) {
      return;
    }

    // Check file size and type
    const validFiles = Array.from(files).filter(file => (
      file.type === `application/${fileType}` && file.size <= 100 * 1024 * 1024
    ));

    if (validFiles.length === files.length) {
      setErrorMessage('');
      setSelectedFiles([...selectedFiles, ...validFiles]);
      onFilesUploaded([...selectedFiles, ...validFiles]);
    } else {
      setErrorMessage('Invalid file. Please make sure it is a PDF file and not greater than 100MB.');
    }
  };

  const handleRemoveFile = (fileName: string) => {
    const updatedFiles = selectedFiles.filter(file => file.name !== fileName);
    setSelectedFiles(updatedFiles);
    onFilesUploaded(updatedFiles);
  };

  return (
    <div className="border border-gray-300 rounded-md p-4 flex flex-col gap-4">
      <div>
        <label
          htmlFor="fileInput"
          className="flex flex-row gap-2 cursor-pointer border border-primary rounded-md shadow px-4 py-2 text-primary max-w-max"
        >
          <Publish />
          {containerLabel}
        </label>
        {multiple ? (
          <input
          type="file"
          id="fileInput"
          className="hidden"
          accept={`.${fileType}`}
          onChange={handleFileChange}
          multiple
        />
        ) : (
          <input
            type="file"
            id="fileInput"
            className="hidden"
            accept={`.${fileType}`}
            onChange={handleFileChange}
          />
        )}
      </div>

      {errorMessage && (
        <>
          <Divider />
          <p className="text-red-500">{errorMessage}</p>
        </>
      )}

      {selectedFiles.length > 0 && (
        <>
          <Divider />
          <div className="flex gap-3 flex-wrap">
            {selectedFiles.map(file => (
              <Chip key={file.name} label={file.name} onDelete={() => handleRemoveFile(file.name)} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
import { TextField, Button, Dialog, DialogContent, IconButton, Divider } from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useLocation } from 'react-router-dom';
import { DialogCommonProps } from '../app.types';
import { NavigationMap } from '../components';
import { getUserId } from '../services/auth.service';
import { createSearchHistory } from '../services/search-history.service';

export const SaveSelection = ({ open, onClose }: DialogCommonProps) => {
  const location = useLocation();
  const [searchLabel, setSearchLabel] = useState('');
  const [formErrors, setFormErrors] = useState<any>({});
  const [touchedFields, setTouchedFields] = useState<any>({});

  const handleInputChange = (event: any) => {
    let errors: any = {};
    const { name, value } = event.target;
    switch (name) {
      case 'searchLabel': {
        if (value.length <= 100) {
          setSearchLabel(value);
        }
        if (!value) {
          errors.searchLabel = 'Search label is required';
        }
        else {
          delete formErrors.searchLabel;
        }
        break;
      }
      default:
        break;
    }
    setFormErrors((prevErrors: any) => {
      return { ...prevErrors, ...errors };
    });
  };

  const handleFieldBlur = (event: any) => {
    const { name } = event.target;
    setTouchedFields((prevTouched: any) => {
      return { ...prevTouched, [name]: true };
    });
  };

  const saveSelection = async () => {
    if (searchLabel) {
      try {
        await createSearchHistory({
          label: searchLabel,
          url: (location.pathname + location.search),
          userId: getUserId(),
          typeName: NavigationMap[location.pathname],
        });
        toast.success('Search saved in history successfully.');
        setSearchLabel('');
        onClose();
      } catch (err: any) {
        if (err.response?.status === 412) {
          toast.error('Search history already exists.');
        } else {
          toast.error('Failed to save search.');
        }
      }
    }
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <div className='flex justify-end items-center bg-white border-b border-gray-200 py-2 px-4'>
        <IconButton
          onClick={onClose}
          size='small'
          className='action'
          sx={{ color: '#AD181D' }}>
          <HighlightOffIcon fontSize='small' />
        </IconButton>
      </div>
      <DialogContent>
        <div className='flex flex-col'>
          <form className='flex flex-col gap-5 w-full h-full'>
            <div className='flex flex-col'>
              <p className='text-3xl font-bold mt-1 mb-2 gradient-text-red'>Save your search</p>
              <p className='text-sm text-gray-500'>Create your research trail</p>
            </div>
            <div className='flex flex-col gap-2'>
              <label className='text-gray-500'>Search label<b className='text-primary ml-1'>*</b></label>
              <TextField
                size='small'
                required
                name='searchLabel'
                placeholder='Enter a label for the search'
                value={searchLabel}
                onChange={handleInputChange}
                onBlur={handleFieldBlur}
                error={!!(formErrors.searchLabel && touchedFields.searchLabel)}
                helperText={
                  touchedFields.searchLabel ? formErrors.searchLabel : ''
                }
              />
            </div>
            <p className='text-sm break-words bg-yellow-100 border border-yellow-400 rounded py-1 px-2'><b>Your search: </b>{location.pathname + location.search}</p>

          </form>
          <div className='flex flex-row gap-3 mt-6 justify-end'>
            <Button
              className='w-20'
              variant='contained'
              color='primary'
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              className='w-20'
              disabled={(searchLabel.length === 0) || Object.keys(formErrors).length > 0}
              variant='contained'
              color='success'
              onClick={() => saveSelection()}
            >
              Save
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>

  );
};
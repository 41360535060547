import React, { useState, ChangeEvent, DragEvent } from 'react';

interface ImageUploadProps {
  onImageUpload: (base64: string) => void;
  image?: string,
}

export const ImageUpload: React.FC<ImageUploadProps> = ({ onImageUpload, image }) => {
  const [error, setError] = useState<string>('');
  const [preview, setPreview] = useState<string>(image || '');

  const handleFile = (file: File) => {
    if (!file) return;

    const isValidType = file.type === 'image/png' || file.type === 'image/jpeg';
    const isValidSize = file.size <= 1024 * 1024; // 1MB

    if (!isValidType) {
      setError('Only PNG and JPG images are allowed.');
      return;
    }

    if (!isValidSize) {
      setError('Image size must be less than 1MB.');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result as string;
      setPreview(base64);
      onImageUpload(base64);
      setError('');
    };
    reader.readAsDataURL(file);
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    handleFile(file!);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files?.[0];
    handleFile(file!);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <div className='max-w-md w-full border border-gray-300 rounded-md p-4 flex flex-col gap-4'>
      <div
        id='drop-zone'
        className='drop-zone border-2 border-dashed border-gray-400 rounded p-4 text-center cursor-pointer transition duration-200 ease-in-out hover:border-gray-600'
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onClick={() => document.getElementById('file-input')?.click()}
      >
        <p>Drag & Drop your image here or click to select</p>
        <input
          type='file'
          id='file-input'
          className='hidden'
          accept='image/png, image/jpeg'
          onChange={handleImageChange}
        />
      </div>
      {preview && (
        <div id='image-preview'>
          <img src={preview} alt='Uploaded' className='max-w-full h-auto rounded' />
        </div>
      )}
      {error && (
        <div id='error-message' className='text-red-500 mt-2'>
          {error}
        </div>
      )}
    </div>
  );
};
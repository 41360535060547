import React, { useEffect, useState } from "react";
import { Bookmark, BookmarkAddOutlined, Download, HighlightOff } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton, CircularProgress, Box } from "@mui/material";
import { toast } from "react-toastify";
import { BookmarkTypes } from "../app.enums";
import { getUserId, isAdmin, isDataOperator } from "../services/auth.service";
import { DocumentViewer, PDFViewer } from "../components";
import { createBookmark, deleteBookmark, fetchBookmarkDetails } from "../services/bookmarks.service";
import { fetchDepartmentFile } from "../services/departments.service";
import { downloadFile } from "../app.utils";

type DepartmentDocumentPreviewDialogProps = {
  open: boolean;
  onClose: () => void;
  departmentData: {
    selectedFile: string,
    selectedFileId: string,
    selectedDepartTitle: string,
  }
};

export const DepartmentDocumentPreview = ({ open, onClose, departmentData }: DepartmentDocumentPreviewDialogProps) => {
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [bookmark, setBookmark] = useState('');
  const [docData, setDocData] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      getDepartmentFile();
      getBookmarkState();
    }
  }, [open]);

  const getDepartmentFile = async () => {
    setLoading(true);

    try {
      const res: any = await fetchDepartmentFile(departmentData.selectedFileId);

      if (res) {
        const isPdf = departmentData.selectedFile.endsWith('pdf');
        setDocData({ blob: res, isPdf });
      } else {
        toast.error('Failed to fetch department file.');
        onClose();
      }
    } catch {
      toast.error('Failed to fetch department file.');
    } finally {
      setLoading(false);
    }
  };

  const bookmarkDepartmentFile = async () => {
    try {
      await createBookmark({
        header: departmentData.selectedDepartTitle,
        detail: departmentData.selectedFile,
        targetId: departmentData.selectedFileId,
        userId: getUserId(),
        typeName: BookmarkTypes.DEPARTMENT,
      });
      toast.success('Bookmark created successfully.');
      setIsBookmarked(true);
    } catch {
      toast.error('Failed to bookmark.');
    }
  };

  const handleDownload = () => {
    if (docData?.blob) {
      downloadFile(docData.blob, departmentData.selectedDepartTitle, 'pdf');
    }
  };

  const handleRemoveBookmark = async () => {
    try {
      await deleteBookmark(getUserId(), bookmark);
      toast.success('Bookmark removed successfully.');
      setIsBookmarked(false);
    } catch {
      toast.error('Failed to delete bookmark.');
    }
  };

  const getBookmarkState = async () => {
    try {
      const response: any = await fetchBookmarkDetails(
        getUserId(),
        departmentData.selectedFileId,
        BookmarkTypes.DEPARTMENT
      );

      if (response && response.data) {
        const bookmark = response.data.find(
          (item: any) => item.detail === departmentData.selectedFile
        );

        if (bookmark) {
          setIsBookmarked(true);
          setBookmark(bookmark.id);
        } else {
          setIsBookmarked(false);
        }
      } else {
        setIsBookmarked(false);
      }
    } catch {
      setIsBookmarked(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="department-dialog-title"
      aria-describedby="department-dialog-description"
      PaperProps={{
        style: {
          minWidth: '930px',
          height: '75vh',
        },
      }}>
      <div className="flex justify-between items-center bg-white border-b border-gray-200 py-2 px-4">
        <span className="font-bold">Department Document</span>
        <div className="flex gap-2 items-center">
          {(isAdmin() || isDataOperator()) && (
            <IconButton size="small" color="primary" onClick={handleDownload}>
              <Download />
            </IconButton>
          )}
          {isBookmarked ? (
            <IconButton
              onClick={handleRemoveBookmark}
              size="small"
              color="primary"
            >
              <Bookmark fontSize="small" />
            </IconButton>
          ) : (
            <IconButton
              onClick={bookmarkDepartmentFile}
              size="small"
              color="primary"
            >
              <BookmarkAddOutlined fontSize="small" />
            </IconButton>
          )}
          <IconButton
            onClick={onClose}
            size="small"
            color="primary"
          >
            <HighlightOff fontSize="small" />
          </IconButton>
        </div>
      </div>
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress color="primary" />
          </Box>
        ) : docData ? (
          docData.isPdf ? (
            <PDFViewer blob={docData.blob} />
          ) : (
            <DocumentViewer blob={docData.blob} />
          )
        ) : (
          <div className="flex flex-col justify-center gap-6 items-center h-full w-full">
            <HighlightOff sx={{ color: '#AD181D', fontSize: '9rem' }} />
            <p className="text-xl">Failed to load department document.</p>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

import { ArrowCircleLeft } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const BackButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const hasPreviousPage = location.key !== 'default';
  return (
    <Button
      disabled={!hasPreviousPage}
      color='primary'
      startIcon={<ArrowCircleLeft fontSize='small' />}
      onClick={() => navigate(-1)}>
      <p className='text-lg'>Back</p>
    </Button>
  );
}
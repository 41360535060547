import React from 'react';

import {
  Tooltip,
  Button,
} from '@mui/material';

import { Save } from '@mui/icons-material';
import { useDialog } from '../providers/dialog.provider';
import { SaveSelection } from '../dialogs';


export const SaveSearchButton = () => {
  const { openDialog, closeDialog } = useDialog();

  const openSaveSelectionDialog = () => {
    openDialog(SaveSelection, {
      onClose: () => {
        closeDialog();
      }
    });
  };

  return (
    <div className='flex flex-row items-center'>
      <Tooltip title={'Save your search and create your research trail.'} placement='left'>
        <span>
          <Button
            onClick={openSaveSelectionDialog}
            size="small"
            className='action'
            color='primary'
            endIcon={(<Save fontSize='medium' />)}>
            Save Search
          </Button>
        </span>
      </Tooltip>
    </div>
  );
};
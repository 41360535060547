import React from 'react';
import { BlogCard } from '.';

interface BlogSectionProps {
  blogs: any[]; // Define a more specific type based on your blog data structure
  onPreview: (id: string) => void;
  title?: string;
  onSeeMore?: () => void;
  seeMoreAfter?: number;
  showAll?: boolean;
}

export const BlogSection: React.FC<BlogSectionProps> = ({
  blogs,
  onPreview,
  onSeeMore,
  title,
  seeMoreAfter = 4,
  showAll = false,
}) => (
  <div>
    {blogs.length > 0 && (
      <div className="flex flex-col gap-4 px-2 mx-auto max-w-7xl">
        {title && (
          <div className="flex flex-row items-center gap-2">
            <p className="pl-4 text-xl font-bold text-gray-700 border-l-8 border-gray-600 lg:text-3xl">{title}</p>
          </div>
        )}
        <div className="flex flex-wrap gap-6">
          {(showAll ? blogs : blogs.slice(0, seeMoreAfter)).map((blog) => (
            <BlogCard key={blog.id} blog={blog} onPreview={onPreview} />
          ))}
        </div>
        {!showAll && blogs.length > seeMoreAfter && (
          <div className="flex justify-start w-full">
            <a className='px-2 font-semibold border-2 rounded-md cursor-pointer text-primary hover:bg-red-100 border-primary' onClick={onSeeMore}>
              See More
            </a>
          </div>
        )}
      </div>
    )}
  </div>
);

import React, { useCallback, useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import LocalOfferIcon from '@mui/icons-material/LocalOffer'; // Icon for Promotion
import AnnouncementIcon from '@mui/icons-material/Announcement'; // Icon for News
import { Newspaper, Close } from "@mui/icons-material";
import { fetchActiveBanners } from "../services/banners.service";

// Mapper object to map types to icons
const typeIconMapper: { [key: string]: JSX.Element } = {
  'Promotion': <LocalOfferIcon className="text-white" />,
  'News': <Newspaper className="text-white" />,
};

export const Banner = () => {
  const [show, setShow] = useState(false);
  const [bannerItems, setBannerItems] = useState<any>([]);

  useEffect(() => {
    loadBannerItems();
  }, []);

  const loadBannerItems = useCallback(async () => {
    try {
      const response: any = await fetchActiveBanners();
      if (response.data.length > 0) {
        setBannerItems(response.data);
        setShow(true);
      }
    } catch {
      setBannerItems([]);
      setShow(false);
    }
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      {show && bannerItems && (
        <div className='bg-primary flex flex-row px-2 py-2 text-white items-center z-[100] shadow-md w-full text-sm'>
          <Marquee className='flex-1' autoFill>
            {bannerItems.map((banner: any) => (
              <div key={banner.id} className="flex flex-row items-center gap-2 px-5">
                {typeIconMapper[banner.type] || <AnnouncementIcon className="text-white" />} {/* Default to News icon if type not found */}
                <span>{banner.title}</span>
              </div>
            ))}
          </Marquee>
          <button onClick={handleClose} className="ml-4">
            <Close fontSize="small" className="text-white" />
          </button>
        </div>
      )}
    </>
  );
}

import React, { useEffect } from 'react'
import PublicRoutes from '../routes/PublicRoutes';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContactSection } from './ContactSection';
import { Header } from '.';

export const PublicLayout = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('requestLink');

    const pathsToCheck = ['/cases/judgment', '/statutes', '/judges', '/courts'];

    if (pathsToCheck.some(path => pathname.includes(path))) {
      localStorage.setItem('requestLink', pathname + search);
      navigate('/auth');
    }
  }, []);

  return (
    <div className='flex flex-col bg-white'>
      <Header />
      <div className='pt-20 min-h-[calc(100vh-5rem)]'>
        <PublicRoutes />
      </div>
      <ContactSection />
    </div>
  )
};
import React from "react";
import { Dialog, DialogActions, Button, IconButton, Divider } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

type ConfirmationPrompProps = {
  title: string;
  message?: string;
  component?: any;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const ConfirmationPrompt = (props: ConfirmationPrompProps) => {
  const { title, message, open, onClose, onConfirm, component } = props;
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-dialog"
    >
      <div className="flex justify-end items-center bg-white border-b border-gray-200 py-2 px-4">
        <IconButton
          onClick={onClose}
          size="small"
          className='action'
          sx={{ color: '#AD181D' }}>
          <HighlightOffIcon fontSize='small' />
        </IconButton>
      </div>

      <div className="flex flex-col gap-4 p-4">
        <p className="text-3xl font-bold gradient-text-red leading-10">{title}</p>
        <div>
          {message && (<p>{message}</p>)}
          {component}
        </div>
      </div>

      <Divider />
      
      <DialogActions>
        <Button
          variant="contained"
          onClick={onClose}
          color="primary"
        >
          No
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={() => {
            onClose();
            onConfirm();
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
// React import
import React, { useEffect, useState } from 'react';


// Material imports
import List from '@mui/material/List';

// Router import
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';

import { currentUserRole, getUserName, logout } from '../services/auth.service';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { isAdmin, isDataOperator, isOrgUser, isUserManager } from '../app.utils';
import { useDialog } from '../providers/dialog.provider';
import { constants } from '../app.constants';
import { ReportProblem } from '../dialogs';
import NameIcon from './NameIcon';
import {
  HomeIcon, NewspaperIcon, BuildingOffice2Icon, BuildingLibraryIcon,
  ArrowLeftStartOnRectangleIcon, CurrencyDollarIcon,
  ExclamationTriangleIcon, CogIcon,
} from '@heroicons/react/24/outline';

import { JudgeIcon, LegalDocument01Icon, LegalDocument02Icon } from "hugeicons-react";

export const NavigationMap: { [key: string]: string } = {
  '/home': 'home',
  '/cases': 'Judgments',
  '/home/search-result': 'home',
  '/cases/judgment': 'Judgments',
  '/departments': 'departments',
  '/judges': 'judges',
  '/statutes': 'statutes',
  '/statutes/:id': 'statutes',
  '/courts': 'courts',
  '/legal-news/list': 'legal news',
  '/legal-news/category': 'legal news',
  '/legal-news/view': 'legal news',
};

export const Navigation = () => {
  const { openDialog, closeDialog } = useDialog();

  let navigate = useNavigate();
  let location = useLocation();

  const NavigationItemsList = [
    {
      name: 'home',
      path: '/home',
      icon: (<HomeIcon className='w-auto h-6' color='inherit' />)
    },
    {
      name: 'Judgments',
      path: '/cases',
      icon: (<LegalDocument01Icon className='w-auto h-6' color='inherit' />)
    },
    {
      name: 'judges',
      path: '/judges',
      icon: (<JudgeIcon className='w-auto h-6' color='inherit' />)
    },
    {
      name: 'courts',
      path: '/courts',
      icon: (<BuildingLibraryIcon className='w-auto h-6' color='inherit' />)
    },
    {
      name: 'statutes',
      path: '/statutes',
      icon: (<LegalDocument02Icon className='w-auto h-6' color='inherit' />)
    },
    {
      name: 'departments',
      path: '/departments',
      icon: (<BuildingOffice2Icon className='w-auto h-6' color='inherit' />)
    },
    {
      name: 'legal news',
      path: '/legal-news/list',
      icon: (<NewspaperIcon className='w-auto h-6' color='inherit' />)
    },
  ];

  const ActionItemsList = [
    {
      name: 'Subscriptions',
      action: () => { navigate('/plans') },
      icon: (<CurrencyDollarIcon className='w-auto h-6' />),
      isVisible: !(isAdmin() || isOrgUser() || isUserManager() || isDataOperator()),
    },
    {
      name: 'Dashboard',
      action: () => { navigate('/dashboard') },
      icon: (<CogIcon className='w-auto h-6' />),
      isVisible: isAdmin() || isUserManager() || isDataOperator(),
    },
    {
      name: 'Report Problem',
      action: () => openReportProblemDialog(),
      icon: (<ExclamationTriangleIcon className='w-auto h-6' />),
      isVisible: !isAdmin(),
    },
    {
      name: 'Logout',
      action: () => logout(),
      icon: (<ArrowLeftStartOnRectangleIcon className='w-auto h-6' />),
      isVisible: true,
    },
  ];

  // Manages selectedScreen state
  const [selectedScreen, setSelectedScreen] = useState(
    NavigationMap[location.pathname]
  );

  /**
   * Toggles selected screen flag
   * @param {string} screen
   * @returns {boolean}
   */
  const isSelected = (screen: string) => screen === selectedScreen;

  useEffect(() => {
    if (location.pathname.includes('judgement')) {
      setSelectedScreen(NavigationMap['/cases']);
    } else {
      setSelectedScreen(NavigationMap[location.pathname]);
    }
  }, [selectedScreen, navigate, location]);

  const openReportProblemDialog = () => {
    openDialog(ReportProblem, {
      onClose: () => {
        closeDialog();
      }
    });
  };

  const [open, setOpen] = useState(true);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <div className='relative z-50 flex flex-row h-full'>
      <div className='flex flex-col flex-1 bg-[#E7E9EB] border-r border-gray-300 shadow text-black h-full overflow-x-hidden overflow-y-auto'>
        {!open && (
          <div className='flex flex-col justify-between w-full h-full'>
            <div className='flex flex-col w-full'>
              <img className='w-24 h-auto px-3 py-6' src={constants.svgs.whiteBalance} alt="EastLaw" />
              <div className='w-full mt-2'>
                {NavigationItemsList.map((navItem) => (
                  <Tooltip key={navItem.name} title={navItem.name} placement='right'>
                    <div key={navItem.name}
                      onClick={() => {
                        navigate(navItem.path);
                      }} className={`min-w-full flex flex-row items-center gap-2 px-4 py-3 cursor-pointer w-full ${(isSelected(navItem.name) ? 'text-primary border-l-4 border-primary shadow bg-[#f3f5f7]' : 'pl-5')}`}>
                      {navItem.icon}
                    </div>
                  </Tooltip>
                ))}
              </div>
            </div>
            <div className='flex-col w-full'>
              <Tooltip title='View Profile' placement='right'>
                <button className='flex flex-row items-center w-full gap-1 p-1 px-3 mb-2' disabled={isOrgUser()} onClick={() => navigate('/user-profile')}>
                  <NameIcon name={getUserName()} />
                </button>
              </Tooltip>
              <div className='w-full mb-4'>
                {ActionItemsList.map((navItem) => (
                  navItem.isVisible && (
                    <Tooltip key={navItem.name} title={navItem.name} placement='right'>
                      <IconButton className={`min-w-full flex flex-row items-center gap-2 px-4 py-3 cursor-pointer w-full hover:text-primary`} color='inherit' onClick={navItem.action}>
                        {navItem.icon}
                      </IconButton>
                    </Tooltip>
                  )
                ))}
              </div>
            </div>
          </div>
        )}
        {open && (
          <div className='w-56 h-full'>
            <div className='flex flex-col justify-between w-full h-full'>
              <div className='flex flex-col w-full gap-4'>
                <img className='p-4' src={constants.svgs.eastLawLogo} alt="EastLaw" />
                <div className='w-full'>
                  {NavigationItemsList.map((navItem) => (
                    <div
                      key={navItem.name}
                      className={`min-w-full flex flex-row items-center gap-2 px-4 py-3 cursor-pointer w-full ${(isSelected(navItem.name) ? 'text-primary border-l-4 border-primary shadow bg-[#f3f5f7]' : 'pl-5')}`}
                      onClick={() => {
                        navigate(navItem.path);
                      }}>
                      {navItem.icon}
                      <p className='capitalize'>{navItem.name}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className='flex-col w-full'>
                <Tooltip title='View Profile' placement='right'>
                  <button className='flex flex-row items-center w-full gap-1 p-1 px-3 mb-2' disabled={isOrgUser()} onClick={() => navigate('/user-profile')}>
                    <NameIcon name={getUserName()} />
                    <div className='flex flex-col items-start justify-start'>
                      <p className='text-gray-700 font-semibold max-w-[7rem] truncate'>
                        {getUserName()}
                      </p>
                      <p className='small-badge'>
                        {currentUserRole()}
                      </p>
                    </div>
                  </button>
                </Tooltip>
                <div className='w-full mb-4'>
                  {ActionItemsList.map((navItem) => (
                    navItem.isVisible && (
                      <div
                        key={navItem.name}
                        className={`min-w-full flex flex-row items-center gap-2 px-4 py-3 cursor-pointer w-full hover:text-primary`}
                        onClick={navItem.action}>
                        {navItem.icon}
                        <p className='capitalize'>{navItem.name}</p>
                      </div>
                    )
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='absolute top-0 cursor-pointer -right-2' onClick={handleDrawerToggle}>
        {open ? (<KeyboardArrowLeft className='bg-white border rounded-full shadow border-primary text-primary' fontSize='small' />) : (<KeyboardArrowRight className='bg-white border rounded-full shadow-md border-primary text-primary' fontSize='small' />)}
      </div>
    </div>
  );
};
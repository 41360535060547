import React from 'react'
import { FeaturesScroller } from '../components';

export const ApplicationTourSection = () => {
  return (
    <div className="flex flex-col items-center max-w-6xl gap-12 mx-auto text-center">
      <p className="text-4xl">Explore <span className='text-4xl font-bold gradient-text-red'>EastLaw</span> features.</p>
      <div>
        <FeaturesScroller />
      </div>
    </div>
  )
};
import React from 'react'

export const AboutSection = () => {
  return (
    <div id="about" className="py-12">
      <div className='flex flex-col items-center justify-center gap-6 mx-auto text-center max-w-7xl'>
        <p className="text-2xl lg:text-4xl">Your Journey to Legal Understanding Begins at <b className='font-bold text-primary'>EastLaw</b></p>
        <div className='flex flex-col items-center justify-center gap-4 text-center text-md lg:text-lg'>
          <p>
            We believe in empowering individuals with clear, accessible legal knowledge, serving as your compass in navigating the complexities of law.
            At Eastlaw, we unravel the intricacies of law, providing anytime, anywhere access to legal insights. Our platform is designed to be your guide through legal labyrinths, offering clarity and solutions that unlock the understanding of legal matters.
            From simplified law at your fingertips to unveiling the essence of legal concepts, we strive to make the law more accessible, ensuring legal clarity is simplified for everyone.
          </p>
          <p>
            Join us on a journey where legal insights are made accessible, empowering your understanding of the law.
          </p>
          {/* <p>
            At EastLaw, we believe in the transformative power of legal knowledge.We’re committed to empowering individuals by offering clear,
            accessible insights into the often-complex world of law. Navigating legal systems and processes can be overwhelming, but we’re here to act as your compass,
            guiding you through every step with simplicity and confidence.
          </p>
          <p>
            Our platform is designed with you in mind—whether you're a curious individual, a business professional, or a legal enthusiast.
            We unravel the intricacies of law, making it easier for you to comprehend its principles and applications. From the convenience of your device,
            EastLaw offers anytime, anywhere access to critical legal insights that help you make informed decisions.
            Whether you're seeking guidance on personal legal matters, business compliance, or staying informed about evolving legal trends,
            EastLaw is your trusted resource.
          </p>
          <p>
            We take pride in simplifying complex legal concepts, breaking them down into digestible information that you can understand without the need for extensive legal training.
            Our mission is to provide clarity, helping you cut through the legal jargon to uncover practical solutions that meet your needs.
          </p>
          <p>
            Through our comprehensive and user-friendly platform, we strive to make the law more accessible for everyone. By distilling complicated regulations, procedures,
            and terminologies, we ensure that you’re equipped with the knowledge you need to approach legal matters with confidence.
          </p>
          <p>
            Join us at EastLaw and embark on a journey where legal insights are not only accessible but also empowering,
            helping you unlock a clearer understanding of the law in all its dimensions.
          </p> */}
        </div>
      </div>
    </div>
  )
};
import React from 'react';
import { TextField, FormControl } from '@mui/material';

interface InputFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  type?: string;
  required?: boolean;
  value: string;
  touched: boolean;
  error: string;
  customValidation?: (value: string) => string | null;
  onChange: (event: { target: { name: string; value: string } }) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  rows?: number;
  multiline?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({
  name,
  label,
  placeholder = '',
  type = 'text',
  required = false,
  multiline = false,
  value,
  touched,
  rows = 1,
  error,
  customValidation,
  onChange,
  onBlur
}) => {

  const handleValidation = () => {
    let errorMessage = '';

    if (required && !value) {
      errorMessage = `${label} is required`;
    } else if (type === 'email' && value && !/\S+@\S+\.\S+/.test(value)) {
      errorMessage = 'Email is invalid';
    } else if (type === 'password' && name === 'password' && value && value.length < 8) {
      errorMessage = 'Password must be at least 8 characters';
    } else if (customValidation) {
      errorMessage = customValidation(value) || '';
    }

    return errorMessage;
  };

  const validationError = handleValidation();

  return (
    <div className="flex flex-col w-full gap-1">
      <label className='text-gray-600'>
        {label}
        {required && <b className="ml-1 text-primary">*</b>}
      </label>
      <FormControl fullWidth>
        <TextField
          color='primary'
          size='small'
          placeholder={placeholder}
          variant='outlined'
          rows={rows}
          name={name}
          multiline={multiline}
          type={type}
          required={required}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          error={!!(validationError && touched)}
          helperText={touched ? validationError : ""}
        />
      </FormControl>
    </div>
  );
};

export default InputField;
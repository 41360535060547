import { Button, Dialog, DialogContent, IconButton } from "@mui/material";
import React from "react";
import { HighlightOff, ReportProblem } from "@mui/icons-material";
import { logout } from "../services/auth.service";

type ForbiddenPromptDialogProps = {
  open: boolean;
  onClose: () => void;
  showLogout: boolean;
};

export const ForbiddenPrompt = ({ open, onClose, showLogout = true }: ForbiddenPromptDialogProps) => {

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <div className="flex justify-end items-center bg-white border-b border-gray-200 py-2 px-4">
        <IconButton
          onClick={() => { logout() }}
          size="small"
          className='action'
          sx={{ color: '#AD181D' }}>
          <HighlightOff fontSize='small' />
        </IconButton>
      </div>
      <DialogContent>
        <div className="flex flex-col justify-center items-center gap-4">
          <ReportProblem sx={{
            fontSize: '5rem',
            color: '#ff9966'
          }} />
          <p className="text-center px-4">
            You do not have permission to access this resource. You might be accessing EastLaw outside of your organization’s network.
          </p>
          {
            showLogout && (
              <Button
                className="w-full mt-4"
                variant="contained"
                color='primary'
                onClick={() => { logout() }}
              >
                Logout
              </Button>)
          }
        </div>
      </DialogContent>
    </Dialog>
  );
};